import React, { useContext, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { usePassword } from "../../../context/PasswordContext"
import { useConfirmPass } from "../../../context/ConfirmPassContext";

export default function Password(props) {
    const { password, setPassword } = usePassword();
    const { confirmPass, setConfirmPass } = useConfirmPass();
    const [errorMsg, setErrorMsg] = useState([])
    
    function handleChange(e) {
        if (props.val==='confirm') {
            setConfirmPass(e)
        } else {
            setPassword(e)
        }
    }

    useEffect(() => {
        if (props.validate === true) {
            var msg = []
            if (password.length > 0) {
                if (password.length < 8) {
                    msg.push("password must be at least 8 characters")
                }
                if (!password?.match(/[A-Z]/)) {
                    msg.push("uppercase required")
                }
                if (!password?.match(/[a-z]/)) {
                    msg.push("lowercase required")
                }
                if (!password?.match(/[0-9]/)) {
                    msg.push("number required")
                }
                if (msg !== []) {
                    props.setPassValid(false)
                } else {
                    props.setPassValid(true)
                }
                setErrorMsg(msg)
            } else {
                setErrorMsg([])
                props.setPassValid(false)
            }
        }
      }, [password]);

    return (
        <Form.Group size="lg" id="formPassword" className="fgroup">
            <Row className="mb-3">
                <Col xs={3} className="col">
                <Form.Label className="lLabel">{props.label}</Form.Label>
                </Col>
                <Col xs="auto">
                <Row>
                    <Form.Control
                        type="password"
                        name="password"
                        onChange={(e) => handleChange(e.target.value)}
                        value={props.val==='confirm' ? confirmPass : password}
                    />
                </Row>
                {errorMsg.map((msg, idx) => (
                    <Row>
                        <Form.Label className="lError" key={idx}>{msg}</Form.Label>
                    </Row>
                ))}
                </Col>
            </Row>
        </Form.Group>
    )
}