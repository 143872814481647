import React, {useState, useEffect } from "react";
import { CognitoIdentityProviderClient, ConfirmForgotPasswordCommand, ResendConfirmationCodeCommand } from "@aws-sdk/client-cognito-identity-provider";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../context/AuthContext"
import { useEmail } from "../../context/EmailContext"
import { usePassword } from "../../context/PasswordContext"
import { useUsername } from "../../context/UsernameContext"
import Email from "./components/Email";
import Password from "./components/Password";
import Code from "./components/Code";
import { useCode } from "../../context/CodeContext";
import { useConfirmPass } from "../../context/ConfirmPassContext"


export default function LoginReset(props) {
  const { password, setPassword } = usePassword();
  const { authState, setAuthState } = useAuth();
  const { email, setEmail } = useEmail();
  const { code, setCode } = useCode("")
  const { confirmPass, setConfirmPass } = useConfirmPass();
  const [passValid, setPassValid] = useState(false)

  useEffect(() => {
    if (password.length > 0 && confirmPass.length > 0) {
      if (password === confirmPass) {
        setPassValid(true)

      }
    }
  }, [password, confirmPass]);

    async function handleSubmit(event) {
      event.preventDefault()
      console.log(event)
      
      const params = {
        ClientId: '5r3kccdtnd273lh69gtfdkhj07',
        Username: email,
        Password: password,
        ConfirmationCode: code
      }

      const client = new CognitoIdentityProviderClient({region: "ap-southeast-2"});
      const command = new ConfirmForgotPasswordCommand(params);
      
      const response = await client.send(command);
      console.log(JSON.stringify(response))
    }

    async function resendCode(event) {
      event.preventDefault()
      console.log(event)
      
      const params = {
        Username: email,
        ClientId: '5r3kccdtnd273lh69gtfdkhj07'
      }
      const client = new CognitoIdentityProviderClient({region: "ap-southeast-2"});
      const command = new ResendConfirmationCodeCommand(params);
      
      const response = await client.send(command);
      console.log(JSON.stringify(response))
    }

    return (
      <div >
        <Form noValidate onSubmit={(e) => handleSubmit(e)} className="mx-auto loginForm" >
          <h2 className="lLabel h2Heading" >Confirmation code has been sent to {email}</h2>
          <Email disabled="true"/>
          <Password label="New Password" validate={true} passValid={passValid} setPassValid={setPassValid} />
          <Password label="Confirm Password" val={props.val}/>
          <Code />
          <Button variant="outline-primary" block="true" size="lg" type="submit" disabled={false} className="lButton" style={{marginLeft: "4ex"}}>
            Reset password
          </Button>
          {/*<Button variant="outline-primary" block="true" size="lg" className="lButton" onClick={(e) => resendCode(e)} style={{marginLeft: "4ex"} }>
            Resend Code
    </Button>*/}
        </Form>
      </div>
    )
}