import React, { createContext, useState, useContext } from 'react';

export const CodeContext = createContext();

export const useCode = () => {
  const context = useContext(CodeContext);
  if (!context) {
    throw new Error('useCode must be used within a CodeProvider');
  }

  return context;
};

export const CodeProvider = ({ children }) => {
  const [code, setCode] = useState('');

  return (
    <CodeContext.Provider value={{ code, setCode }}>
      {children}
    </CodeContext.Provider>
  );
};
