import React from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';

import { AuthProvider}  from "./context/AuthContext"
import { EmailProvider } from "./context/EmailContext"
import { PasswordProvider } from "./context/PasswordContext"
import { UsernameProvider } from "./context/UsernameContext"
import { CodeProvider } from "./context/CodeContext"
import Main from "./components/Main"

function App () {

  return (
    <AuthProvider>
      <UsernameProvider>
        <EmailProvider>
          <PasswordProvider>
              <CodeProvider>
                <Main className='page'/>
            </CodeProvider>
          </PasswordProvider>
        </EmailProvider>
      </UsernameProvider>
    </AuthProvider>
  );
};


export default App;