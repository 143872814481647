import React, { useState } from "react";
import "./Login.css";
import "../../App.css";
import LoginInitial from "./LoginInitial";
import LoginReset from "./LoginReset";
import LoginChange from "./LoginChange";
import { useAuth } from "../../context/AuthContext"
import { ConfirmPassProvider } from "../../context/ConfirmPassContext"


export default function Login() {
  const { authState, setAuthState } = useAuth();
  const [session, setSession ] = useState();

  return (
    <ConfirmPassProvider>
    <div className="loginDiv">
        {authState==='INITIAL' || authState==='NotAuthorizedException' ? <LoginInitial className="fgroup" setSession={setSession} /> : null}
        {authState==='NEW_PASSWORD_REQUIRED' ? <LoginChange val="confirm" session={session} /> : null}
        {authState==='FORGOT_PASSWORD' ? <LoginReset val="confirm" /> : null}
    </div>
    </ConfirmPassProvider>
  );
}