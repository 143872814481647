import { useState } from "react"
import { CognitoIdentityProviderClient,ForgotPasswordCommand, InitiateAuthCommand, GetUserCommand, ConfirmForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useAuth } from "../../context/AuthContext"
import { useEmail } from "../../context/EmailContext"
import { usePassword } from "../../context/PasswordContext"
import { useUsername } from "../../context/UsernameContext"
import Email from "./components/Email"
import Password from "./components/Password"
import "./Login.css";


export default function LoginInitial(props) {
  const { username, setUsername } = useUsername();
  const { password, setPassword } = usePassword();
  const { authState, setAuthState } = useAuth();
  const { email, setEmail } = useEmail();
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [msg, setMsg] = useState("")

    async function handleSubmit(event) {
      event.preventDefault()
      setMsg("")
      setIsSubmitting(true)
      const params = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: '5r3kccdtnd273lh69gtfdkhj07',
        AuthParameters: {
          USERNAME: email,
          PASSWORD: password
        }
      };
      
      console.log(email, password)
      const client = new CognitoIdentityProviderClient({ region: "ap-southeast-2" });
      const command = new InitiateAuthCommand(params);
      var response
      try {
        await client.send(command).then((res) => {
          console.log(JSON.stringify(res))
          if (res.$metadata.httpStatusCode === 200) {
            if (res.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
              setPassword("")
              setAuthState(res.ChallengeName)
              props.setSession(res.Session)
            } else if (res.AuthenticationResult.AccessToken) {
              setAuthState('AUTHENTICATED')
              setUsername(email)
              localStorage.setItem('cognito-accessToken', res.AuthenticationResult.AccessToken)
              localStorage.setItem('cognito-idToken', res.AuthenticationResult.IdToken)
              localStorage.setItem('cognito-refreshToken', res.AuthenticationResult.RefreshToken)
              const command = new GetUserCommand({
                AccessToken: res.AuthenticationResult.AccessToken,
              });
              
              client.send(command).then(res => {
                console.log(res);
              })
             }
          } else {
            if (response !== undefined) {
              console.log(JSON.stringify(response))
              console.log(response.$metadata.httpStatusCode)
              setAuthState(response.ChallengeName) 
            } else {
              console.log(JSON.stringify(response), "here2")
            }
          }
          response = res
          //console.log(res)
        })
      } catch (e) {
        if (e.name ==='NotAuthorizedException') {
          setAuthState(e.name)
          setMsg('Invalid username or password')
        }
        console.log(e)
        console.log(JSON.stringify(e))
      }
      setIsSubmitting(false)
    }

    async function forgotPass(e) {
      e.preventDefault()
      const params = {
          ClientId: '5r3kccdtnd273lh69gtfdkhj07',
          Username: 'bshih79j@gmail.com'
          }
  
      const client = new CognitoIdentityProviderClient({region: "ap-southeast-2"});
      const command = new ForgotPasswordCommand(params);
      const response = await client.send(command);
      
      if (response.$metadata.httpStatusCode===200) {
        setMsg("")
        setAuthState("FORGOT_PASSWORD")
      } else {
        setMsg("An error has occured.")
      }
      console.log(JSON.stringify(response))
    }

    console.log(msg)

    return (
      <>
        <Form noValidate onSubmit={(e) => handleSubmit(e)} className="mx-auto loginForm">
          <Email />
          <Password label="Password" />
          <Form.Group className="fgroup">
            <Button variant="outline-primary" size="lg" type="submit" disabled={isSubmitting} className="lButton">
              Login
            </Button>
            <Button variant="outline-primary" size="lg" type="button" onClick={(e) => forgotPass(e)} className="lButton">
              Forgot
          </Button>
          {msg ? <Form.Label className="lError" >{msg}</Form.Label> : null}
          </Form.Group>
        </Form>
      </>
    )
}