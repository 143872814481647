import React, { useContext } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { useCode } from "../../../context/CodeContext"

export default function Code(props) {
    const { code, setCode } = useCode();

    return (
        <Form.Group size="lg" id="formCode" className="fgroup">
            <Row className="mb-3">
              <Col xs={3} className="col">
                <Form.Label className="lLabel">Confirmation Code</Form.Label>
              </Col>
              <Col xs="auto">
                <Row>
                    <Form.Control
                    id="code"
                    type="text"
                    name="code"
                    onChange={(e) => setCode(e.target.value)}
                    disabled={props.disabled}
                    value={code}
                    />
                </Row>
              </Col>
            </Row>
          </Form.Group>
    )
}